<template>
  <div>
    <q-form ref="editForm">
      <c-card title="공정상세" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-4">
            <c-text
              :editable="editable"
              :required="true"
              label="공정명"
              name="processName"
              v-model="data.processName">
            </c-text>
          </div>
          <div class="col-2">
            <c-text
              :editable="editable"
              label="공정코드"
              name="processCd"
              v-model="data.processCd">
            </c-text>
          </div>
          <div class="col-2">
            <c-plant
              :editable="editable"
              label="LBLPLANT"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-2">
            <c-radio
              :editable="editable"
              :comboItems="psmYnItems"
              label="PSM대상"
              name="psmFlag"
              v-model="data.psmFlag">
            </c-radio>
          </div>
          <div class="col-2">
            <c-radio
              :editable="editable"
              :comboItems="useFlagItems"
              label="LBLUSEFLAG"
              name="useFlag"
              v-model="data.useFlag">
            </c-radio>
          </div>
          <div class="col-5">
            <c-text
              :editable="editable"
              label="주재료"
              name="mainMaterial"
              v-model="data.mainMaterial">
            </c-text>
          </div>
          <div class="col-5">
            <c-text
              :editable="editable"
              label="생산품"
              name="productName"
              v-model="data.productName">
            </c-text>
          </div>
          <div class="col-2">
            <c-text
              :editable="editable"
              label="근로자수"
              name="workerCnt"
              suffix="명"
              type="number"
              v-model="data.workerCnt">
            </c-text>
          </div>
          <div class="col-12">
            <c-textarea
              :counter="true"
              :editable="editable"
              label="공정설명"
              name="processDesc"
              :rows="2"
              v-model="data.processDesc">
            </c-textarea>
          </div>
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfoUp"
              :editable="editable"
              label="공정 사진">
            </c-upload>
          </div>
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo3"
              :editable="editable"
              label="그 밖의 유해위험정보">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-detail-pop',
  props: {
    processCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachInfoUp: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UP',
        taskKey: '',
      },
      attachInfo3: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_HAZARD',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      psmYnItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '해당없음' },
      ],
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = false;
      // api scope
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.getData()
    },
    getData() {
      this.$http.url = this.$format(this.detailUrl, this.processCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.$set(this.attachInfoUp, 'taskKey', this.processCd)
        this.$set(this.attachInfo3, 'taskKey', this.processCd);
      },
      () => {
      });
    },
  }
};
</script>
